import { FC, Fragment, memo, useEffect, useMemo, useState } from 'react';
import type { Room } from '@entities/Room';
import { api } from 'src/api/services';

import EgressHelper from '@livekit/egress-sdk';
import { Room as LivekitRoom, RoomEvent, Track } from 'livekit-client';
import {
  LiveKitRoom,
  ParticipantContext,
  ParticipantLoop,
  ParticipantName,
  ParticipantTile,
  RoomAudioRenderer,
  TrackLoop,
  TrackRefContext,
  TrackReference,
  TrackReferenceOrPlaceholder,
  VideoTrack,
  useLiveKitRoom,
  useParticipants,
  useRoomContext,
  useTracks,
} from '@livekit/components-react';

// @ts-ignore
import { ReactComponent as ChatterLogo } from '../../assets/icons/Chatter.svg';
// @ts-ignore
import { ReactComponent as ChatterText } from '../../assets/icons/ChatterText.svg';
// @ts-ignore
import { ReactComponent as Person } from '../../assets/icons/person.svg';
// @ts-ignore
import { ReactComponent as AppStoreLogo } from '../../assets/icons/logo-apple-appstore.svg';
// @ts-ignore
import { ReactComponent as GoogleStoreLogo } from '../../assets/icons/logo-google-playstore.svg';
// @ts-ignore
import { ReactComponent as VerifiedIcon } from '../../assets/icons/VerifiedIcon.svg';

// @ts-ignore
import { ReactComponent as VideoCam } from '../../assets/icons/videocam-outline.svg';

import { UserMetadata } from '@entities/User';
import StageParticipant from 'src/components/StageParticipant';
import React from 'react';
import clsx from 'clsx';

type RoomPageProps = {
  url: string;
  token: string;
  layout: string;
};

const VideoStageParticipant = memo(({ trackRef }: { trackRef: TrackReference }) => {
  const isSpeaking = trackRef.participant.isSpeaking;

  let userMetadata: UserMetadata | null = null;

  if (trackRef.participant.metadata) {
    userMetadata = JSON.parse(trackRef.participant.metadata);
  }

  return (
    <div
      className={clsx(
        'relative bg-[#0C0A07] rounded-3xl overflow-hidden border-[2px] transition-all',
        isSpeaking ? 'border-[#30D6D9]' : 'border-transparent',
      )}
    >
      <VideoTrack className='top-[-25%] absolute min-w-full min-h-full object-contain' trackRef={trackRef} />
      <div className='bottom-3 left-[50%] absolute flex items-center gap-2 bg-[#1F1C1966] backdrop-blur-sm px-[0.5rem] rounded-lg text-[2rem] text-white translate-x-[-50%]'>
        <VerifiedIcon width={25} height={25} />
        {userMetadata?.user_first_name}
      </div>
    </div>
  );
});

const CompositeTemplate = ({ layout: initialLayout }: { layout: string }) => {
  const room = useRoomContext();
  // const participants = useParticipants({ room });
  const screenShareVideoTracks = useTracks([Track.Source.ScreenShare], { room });
  const screenShareAudioTracks = useTracks([Track.Source.ScreenShareAudio], { room });
  // const trackRefs = useTracks([Track.Source.Camera], { room });
  const [layout, setLayout] = useState(initialLayout);
  useEffect(() => {
    console.log(screenShareAudioTracks);
  }, [screenShareAudioTracks]);
  useEffect(() => {
    if (room) {
      EgressHelper.setRoom(room);

      // Egress layout can change on the fly, we can react to the new layout
      // here.
      EgressHelper.onLayoutChanged((newLayout) => {
        setLayout(newLayout);
      });

      // start recording when there's already a track published
      let hasTrack = false;
      for (const p of Array.from(room.remoteParticipants.values())) {
        if (p.trackPublications.size > 0) {
          hasTrack = true;
          break;
        }
      }

      if (hasTrack) {
        EgressHelper.startRecording();
      } else {
        room.once(RoomEvent.TrackSubscribed, () => EgressHelper.startRecording());
        EgressHelper.startRecording();
      }
    }
  }, [room]);

  return (
    <div className='aspect-video w-full'>
      <TrackLoop tracks={screenShareVideoTracks}>
        <TrackRefContext.Consumer>
          {(trackRef) => {
            if (trackRef && 'publication' in trackRef && trackRef.publication) {
              return <VideoTrack className='absolute min-w-full min-h-full object-contain' trackRef={trackRef} />;
            }
            // handle the placeholder case here
            return <div className={clsx('relative bg-[#0C0A07] rounded-3xl overflow-hidden')}></div>;
          }}
        </TrackRefContext.Consumer>
      </TrackLoop>
      <RoomAudioRenderer muted={false} volume={1} />
    </div>
  );
};

const RoomPage: FC<RoomPageProps> = ({ url, token, layout }) => {
  const [error, setError] = useState<Error>();
  if (!url || !token) {
    return <div className='error'>missing required params url and token</div>;
  }

  return (
    <LiveKitRoom className='bg-[#0C0A07] p-[1rem] no-scrollbar' serverUrl={url} token={token}>
      {error ? <div className='error'>{error.message}</div> : <CompositeTemplate layout={layout} />}
    </LiveKitRoom>
  );
};

export default memo(RoomPage);

// PARTICIPANT WITHOUT CAMERA

{
  /* <div className='flex justify-center items-center'>
<div className='flex flex-col items-center gap-[16px]'>
  <div
    style={{ backgroundImage: `url(${roomDetails?.host.avatar})` }}
    className='bg-[#202020] bg-cover rounded-full w-[240px] h-[240px]'
  ></div>
  <div className='relative flex items-center font-lato text-[#F5F1E9] text-[28px] leading-[32px]'>
    <VerifiedIcon className='left-[-36px] absolute' width={32} height={32} fill='#30D6D9' />
    <p>{roomDetails?.host.first_name}</p>
  </div>
  <p className='text-[#D5CEC0] text-[24px] leading-[28px]'>Host</p>
</div>
</div> */
}

// PARTICIPANT WITH CAMERA
{
  /* <div className='relative bg-[#ffffee] rounded-[16px]'>
<div className='bottom-[8px] left-[50%] absolute flex items-center gap-2 bg-[#1F1C1966] px-[12px] py-[10px] rounded-[12px] translate-x-[-50%] place-self-center'>
  <VerifiedIcon width={32} height={32} fill='#30D6D9' />
  <p className='font-lato text-[#F5F1E9] text-[28px] leading-[32px]'>Vasyl</p>
  <p className='text-[#F5F1E9] text-[28px] leading-[36px]'>Host</p>
</div>
</div> */
}

// livekit-cli test-egress-template
//   --base-url http://localhost:3000 \
//   --url https://dev-media.chattersocial.io
//   --api-key APIidGzFR7PNWQs
//   --api-secret SAzQ7YHO9ShgskfoAtIUCvyOvVrRxXXXPicJLO4nw2L
//   --room 123 --layout test --publishers 3
