import { LiveKitRoom } from '@livekit/components-react';
import RoomPage from './pages/Room';
import { useEffect, useState } from 'react';
import { api } from './api/services';
import { Room as LivekitRoom, RoomEvent } from 'livekit-client';
import EgressHelper from '@livekit/egress-sdk';
import { Room } from '@entities/Room';

const LOGIN_DATA = {
  email: 'justin@chattersocial.io',
  password: 'Watson334!',
};

function App() {
  return (
    // <LiveKitRoom className='bg-[#0C0A07]' serverUrl={Ed} token={TOKEN}>
    <RoomPage
      url={EgressHelper.getLiveKitURL()}
      token={EgressHelper.getAccessToken()}
      layout={EgressHelper.getLayout()}
    />
    // </LiveKitRoom>
  );
}

export default App;
